@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }

  @media (max-width:768px) {
    padding: 32px;
  }
}

.allServicesBox {
  position: relative;
  padding-bottom: 100px;

  & a {
    font-size: 16px;
    line-height: 18px;
    padding: 16px 34px;
    font-family: 'bilo', sans-serif;
    text-align: center;
    display: inline-block;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    transition: all .15s ease;
    top: 0;
    background: #ff6900;
    text-shadow: 0 1px 3px rgb(36 180 126 / 40%);
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 4px;
    border: 1px solid #ff6900;

    &:hover {
      transform: translateY(-1px);
      background: #868686;
      border-color: #868686;
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    }
  }
}

.attendeeManagementBox {
  position: relative;
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.promotixContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 100px 0 0 0;
  }
}

.eventBrandLogo {
  position: relative;
}

.heroBox {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 180px 0 140px;
  }
}

.promoGrid {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 50px 0 50px;
  }

  & h3 {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    letter-spacing: .4px;
    margin-bottom: 10px;
    color: #ff6900;
    padding: 0;
  }

  & p {
    font-size: 17px;
    line-height: 24px;
    color: #56676b;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0;
  }
}

.makePromotix {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 100px 0 0;
  }

  & header {
    & h2 {
      font-size: 42px;
      font-weight: 800;
      letter-spacing: .8px;
      color: #333;
      margin: 0;
      padding: 0;
    }

    & p {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #56676b;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
      text-decoration: none;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

.eventDetailsBox {
  position: relative;
  border-bottom: 1px solid #e7e7e7;
}

.powerfulInteragtion {
  padding: 40px 0;
  position: relative;
  transition: all 1.3s ease;

  & header {
    display: none;
  }

  &:hover {
    transition: all 1.3s ease;

    &>div {
      filter: blur(6px) grayscale(1);
      width: 55%;
      transition: all .5s ease;
    }

    & header {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      transition: all 1.3s ease;

      & a {
        font-size: 16px;
        line-height: 18px;
        padding: 16px 34px;
        font-family: 'bilo', sans-serif;
        text-align: center;
        display: inline-block;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        transition: all .15s ease;
        top: 0;
        background: #ff6900;
        text-shadow: 0 1px 3px rgb(36 180 126 / 40%);
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 4px;
        border: 1px solid #ff6900;

        &:hover {
          transform: translateY(-1px);
          background: #868686;
          border-color: #868686;
          box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        }
      }
    }
  }
}

.marketingBoxWrapper {
  padding: 0 0 60px;
  position: relative;
}