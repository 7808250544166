@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.fiveColumnBox {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(5, 1fr);
    padding: 0;
    max-width: 840px;

    &>div {
      display: flex;
      align-items: center;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.makeMorePromotixGrid {
  &>div {
    &:nth-child(1) {
      & div {
        border-radius: 0;

        & img {
          border-radius: 0;
        }
      }
    }

    &:nth-child(2) {
      & h4 {
        font-size: 20px;
        line-height: 27px;
        font-weight: 600;
        letter-spacing: .4px;
        margin-bottom: 10px;
        color: #ff6900;
      }

      & p {
        font-size: 17px;
        line-height: 24px;
        color: #56676b;
        letter-spacing: 0;
        font-weight: 500;
      }

      & hr {
        margin: 40px 0;
        border: none;
        border-top: 1px solid #cdcdcd;
      }

      & a {
        font-size: 16px;
        line-height: 18px;
        padding: 16px 34px;
        text-align: center;
        display: inline-block;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        transition: all .15s ease;
        top: 0;
        background: #ff6900;
        text-shadow: 0 1px 3px rgb(36 180 126 / 40%);
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 4px;
        border: 1px solid #ff6900;

        &:hover {
          transform: translateY(-1px);
          background: #868686;
          border-color: #868686;
          box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        }
      }
    }
  }
}

.eventDetailsGrid {
  gap: 0;
  padding: 0;

  &>div {
    padding: 80px 70px 90px 40px;

    &:nth-child(1) {
      border-right: 1px solid #e7e7e7;
    }

    & img {
      width: 25px;
      height: 25px;
    }

    & h3 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 600;
      letter-spacing: .4px;
      margin-bottom: 10px;
      color: #ff6900;
      padding: 0;
    }

    & p {
      font-size: 17px;
      line-height: 24px;
      color: #56676b;
      letter-spacing: 0;
      font-weight: 500;
      padding: 0;
      margin: 0;
    }
  }
}

.integrationBox {
  align-items: center;
  filter: blur(0px) grayscale(1);
  transition: all 1.3s ease;
  padding: 0;

  &:hover {}

  &>div {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

.attendeeManagement {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  padding: 32px;

  & h2 {
    font-size: 42px;
    font-weight: 800;
    letter-spacing: .8px;
    color: #333;
    margin: 0;
    padding: 0;
    text-decoration: none;
    margin-bottom: 15px;
  }

  & p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: #56676b;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
  }
}

.attendeeManagementGrid {
  &>div {
    z-index: 1;
    padding: 30px;
    transition: all .15s ease;
    border: 1px solid #e7e7e7;
    background: #fff;

    &:hover {
      box-shadow: 0px 0px 36px rgb(0 0 0 / 18%);
    }

    &>div {
      &:nth-child(1) {
        width: 40px;
      }

      &:nth-child(2) {
        & h3 {
          font-size: 20px;
          line-height: 27px;
          font-weight: 600;
          letter-spacing: .4px;
          margin-bottom: 10px;
          color: #ff6900;
          padding: 0;
        }

        & p {
          font-size: 17px;
          line-height: 24px;
          color: #56676b;
          letter-spacing: 0;
          font-weight: 500;
          padding: 0;
        }
      }
    }
  }
}

.creatorsPromotixGrid {
  max-width: 100%;
  padding: 0;
  gap: 0;
  grid-template-columns: repeat(4, 1fr);

  &>div {
    position: relative;

    &:hover {
      &>div {

        &:nth-child(2) {
          bottom: 0;
          display: block;
          transition: .5s ease;
        }
      }
    }

    &>div {
      &:nth-child(1) {
        & div {
          border-radius: 0;
          position: relative;
          height: 300px;
          overflow: hidden;

          & img {
            border-radius: 0;
          }
        }
      }

      &:nth-child(2) {
        z-index: 4;
        position: absolute;
        text-align: center;
        padding: 30px 0 0;
        left: 0;
        right: 0;
        bottom: -300px;
        background: #f5f7fa;
        display: none;

        & h3 {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          color: #333;
          padding: 0 30px;
        }

        & p {
          font-size: 17px;
          line-height: 24px;
          color: #56676b;
          letter-spacing: 0;
          font-weight: 500;
          padding: 0 30px;
        }

        & a {
          padding: 15px 30px;
          display: inline-block;
          margin-top: 30px;
          background: #ff6900;
          color: #fff;
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
}

.eventCreatorsPromotix {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  padding: 32px 0 50px;

  & h2 {
    font-size: 42px;
    font-weight: 800;
    letter-spacing: .8px;
    color: #333;
    margin: 0;
    padding: 0;
    text-decoration: none;
    margin-bottom: 15px;
  }

  & p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: #56676b;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
  }
}