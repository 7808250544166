@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  composes: baseColumn;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 64px;
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.marketingGrid {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;

  &>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-auto-flow: dense;
    gap: 0;

    @media (--viewportMedium) {
      gap: 64px;
      flex-direction: unset;
    }

    &:nth-child(even) {
      @media (--viewportMedium) {
        flex-direction: row;
      }
    }

    &>div {
      &:nth-child(2) {
        & h1 {
          font-size: 42px;
          font-weight: 800;
          letter-spacing: .8px;
          color: #333;
          margin: 0;
          padding: 0;
          text-decoration: none;
          margin-bottom: 15px;
        }

        & p {
          font-size: 17px;
          line-height: 24px;
          color: #56676b;
          letter-spacing: 0;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }

        & ul {
          padding-left: 26px;

          & li {
            font-size: 17px;
            line-height: 24px;
            color: #56676b;
            letter-spacing: 0;
            font-weight: 500;
          }
        }

        & a {
          font-size: 16px;
          line-height: 18px;
          padding: 16px 34px;
          font-family: 'bilo', sans-serif;
          text-align: center;
          display: inline-block;
          letter-spacing: 1px;
          color: #fff;
          margin-bottom: 0;
          position: relative;
          z-index: 1;
          transition: all .15s ease;
          top: 0;
          background: #ff6900;
          text-shadow: 0 1px 3px rgb(36 180 126 / 40%);
          text-transform: uppercase;
          font-weight: 600;
          cursor: pointer;
          box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
          border-radius: 4px;
          border: 1px solid #ff6900;
          &:hover {
            transform: translateY(-1px);
            background: #868686;
            border-color: #868686;
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
          }
        }
      }
    }
  }
}

.streamingGrid {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;

  &>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-auto-flow: dense;
    gap: 0;

    @media (--viewportMedium) {
      gap: 64px;
      flex-direction: row-reverse;
    }

    &:nth-child(even) {
      @media (--viewportMedium) {
        flex-direction: row;
      }
    }

    &>div {
      &:nth-child(2) {
        & h1 {
          font-size: 42px;
          font-weight: 800;
          letter-spacing: .8px;
          color: #333;
          margin: 0;
          padding: 0;
          text-decoration: none;
          margin-bottom: 15px;
        }

        & p {
          font-size: 17px;
          line-height: 24px;
          color: #56676b;
          letter-spacing: 0;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }

        & ul {
          padding-left: 26px;

          & li {
            font-size: 17px;
            line-height: 24px;
            color: #56676b;
            letter-spacing: 0;
            font-weight: 500;
          }
        }

        & a {
          font-size: 16px;
          line-height: 18px;
          padding: 16px 34px;
          font-family: 'bilo', sans-serif;
          text-align: center;
          display: inline-block;
          letter-spacing: 1px;
          color: #fff;
          margin-bottom: 0;
          position: relative;
          z-index: 1;
          transition: all .15s ease;
          top: 0;
          background: #ff6900;
          text-shadow: 0 1px 3px rgb(36 180 126 / 40%);
          text-transform: uppercase;
          font-weight: 600;
          cursor: pointer;
          box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
          border-radius: 4px;
          border: 1px solid #ff6900;

          &:hover {
            transform: translateY(-1px);
            background: #868686;
            border-color: #868686;
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
          }
        }
      }
    }
  }
}