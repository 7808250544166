.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width:768px) {
    padding: 32px 0;
  }
}

.heroHeading {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1;
  margin-bottom: 18px;
  color: #fff;
  text-shadow: 0px 0px 20px rgb(0 0 0 / 60%);
}

.heroImage {
  flex: 1;
}

.heroData {
  flex: 2;

  & p {
    padding: 0;
    margin-top: 0;
    color: #fff;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    text-shadow: 0px 0px 20px rgb(0 0 0 / 60%);
  }

  & a {
    margin-top: 30px;
    color: #fff;
    text-shadow: none;
    background: transparent;
    font-size: 16px;
    line-height: 18px;
    padding: 16px 34px;
    border: 1px solid #fff !important;
    transition: all .15s ease;
    text-transform: uppercase;

    &:hover {
      transform: translateY(-1px);
      background: #868686;
      border-color: #868686;
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    }
  }
}

.eventLogo {
  &>div {
    width: 128px;
    height: 128px;
    margin: auto;
  }

  & img {
    width: 128px;
    height: 128px;
  }
}

.eventDescription {
  margin-top: 15px;

  & p {
    &:nth-child(1) {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #56676b;
      letter-spacing: 0;
      padding: 0;
      margin-bottom: 15px;

      @media (max-width:768px) {
        padding: 0 32px;
      }
    }

    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      color: #56676b;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
      text-decoration: none;
      margin-bottom: 15px;

      @media (max-width:768px) {
        padding: 0 32px;
      }
    }
  }
}

.ticketHeading {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: .4px;
  margin-bottom: 10px;
  color: #ff6900;
  padding: 0;
}

.compareLink {
  text-align: center;

  & p {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    letter-spacing: .4px;
    margin-bottom: 10px;
    color: #ff6900;
    padding: 0;
    margin: 50px 0 0;

    & a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.detailLogo {
  width: 20px;
}